import { theme } from "@/layout/theme";
import { AccordionItemBase, AccordionTitleBase } from "@jog/react-components";
import classNames from "classnames";
import React, { memo } from "react";
const TitleFC = ({ number, title, isActive, isStepCompleted, className, }) => {
    return (<h1 className={classNames("text-lg font-medium", isActive ? "text-black" : isStepCompleted ? "text-black" : "text-gray-main", className)}>{`${number}. ${title}`}</h1>);
};
const AccordionTitle = memo(AccordionTitleBase({
    TitleFC,
    spanCss: "text-black font-primary font-medium",
    activeClass: "pt-3.75 px-0 pb-5",
}));
export const AccordionItem = React.memo(AccordionItemBase({
    AccordionTitleFC: AccordionTitle,
    defaultBgColor: theme.color.white,
    stepCompletedBgColor: theme.color.white,
}));
