import { GRichText } from "@/components/RichText/RichText";
import { NewsForm } from "@/containers/SocialSection/NewsForm";
import { useLayout } from "@/lib/preFetch/preFetchLayout";
import { localStorage } from "@jog/shared";
import { isFilled } from "@prismicio/helpers";
import { Container } from "components";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
export const EmailSubscription = () => {
    var _a;
    const [isSubscriptionVisible, setSubscriptionVisible] = useState(false);
    const [isSubscriptionStep, setSubscriptionStep] = useState(false);
    const layout = useLayout();
    const isDeclinedSevenDaysAgo = (date) => {
        if (!date)
            return true;
        return dayjs().diff(date, "days") > 7;
    };
    const declineSubscription = () => {
        setSubscriptionVisible(false);
        localStorage.setItem("newsletter", {
            isSubscribed: false,
            date: dayjs().toString(),
        });
    };
    useEffect(() => {
        const newsletterStorage = localStorage.getItem("newsletter");
        if ((newsletterStorage === null || newsletterStorage === void 0 ? void 0 : newsletterStorage.isSubscribed) || !isDeclinedSevenDaysAgo(newsletterStorage === null || newsletterStorage === void 0 ? void 0 : newsletterStorage.date)) {
            setSubscriptionVisible(false);
        }
        else {
            setSubscriptionVisible(true);
        }
    }, []);
    if (!isSubscriptionVisible)
        return <></>;
    const data = (_a = layout === null || layout === void 0 ? void 0 : layout.layout) === null || _a === void 0 ? void 0 : _a.emailSubscription;
    if (!(data === null || data === void 0 ? void 0 : data.display))
        return <></>;
    return (<div className="relative p-2.5 text-sm" style={{ backgroundColor: data.background_color }}>
            <Container>
                <div className="flex flex-col justify-evenly px-2 lg:flex-row lg:items-baseline lg:p-2.5">
                    {isFilled.richText(data.message) && (<GRichText paragraphClass="flex justify-center lg:items-center mb-0" field={data.message}/>)}
                    {isSubscriptionStep ? (<div className="mt-2.5 flex justify-center md:min-w-[550px]">
                            <NewsForm source="header"/>
                        </div>) : (<div className="mt-2.5 flex flex-col justify-center gap-y-2 xs:flex-row xs:gap-y-0 lg:min-w-[320px]">
                            <button className="border-none bg-none py-0 pr-2.5 pl-0 underline" onClick={declineSubscription}>
                                {isFilled.richText(data.decline_message) && (<GRichText paragraphClass="flex justify-center lg:items-center mb-0" field={data.decline_message}/>)}
                            </button>
                            <PrimaryButton onClickFunction={() => setSubscriptionStep(true)}>
                                {isFilled.richText(data.accept_message) && (<GRichText paragraphClass="flex justify-center lg:items-center mb-0" field={data.accept_message}/>)}
                            </PrimaryButton>
                        </div>)}
                </div>
            </Container>
        </div>);
};
