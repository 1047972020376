import { I18n, TotalPriceBase, TotalPriceWrapperBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import React from "react";
import { Price } from "../Price/Price";
const TotalPriceWrapper = styled(TotalPriceWrapperBase) `
    margin-bottom: 30px;
    font-size: 18px;
`;
export const TotalPrice = React.memo(TotalPriceBase({
    TotalLabel: () => <I18n as="div" t="Total inc. VAT"/>,
    TotalPriceWrapper,
    priceFontSize: "18px",
    Price,
}));
