import { AddedProductModalBase, useGate } from "@jog/react-components";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { ArrowText } from "../ArrowText/ArrowText";
import { Modal } from "../Modal/Modal";
import { Price } from "../Price/Price";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
const AddedProductModalBS = AddedProductModalBase({
    cartItemCss: "border-b border-b-black",
    modalItemInfoTitleCss: "text-sm text-black",
    ArrowTextFC: ArrowText,
    ModalFC: Modal,
    PriceFC: Price,
    ArrowButtonFC: PrimaryButton,
});
const AddedProductModalFC = (props) => {
    const { cartStore } = useStores();
    const { value: newCartPopup } = useGate("cart");
    if (newCartPopup)
        return <></>;
    return <AddedProductModalBS {...props} cartStore={cartStore}/>;
};
export const AddedProductModal = observer(AddedProductModalFC);
