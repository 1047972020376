import PrimaryButton from "@/components/PrimaryButton/PrimaryButton";
import { BasketCartItem } from "@/containers/CartContent/CartItem/BasketCartItem";
import { Link, useGate, useI18n } from "@jog/react-components";
import { ArrowText, Price } from "components";
import { observer } from "mobx-react";
import { useStores } from "stores";
const HeaderBasketCartFooter = ({ cartViewBody }) => {
    const { $t } = useI18n();
    return (<div className="mt-6 mb-7.5 flex w-full justify-end text-22">
            <div>
                <div className="flex justify-between">
                    <div className="mr-7.5 font-primary text-gray-dracula">{$t("Total")}</div>
                    {cartViewBody && <Price fontSize="22px" price={cartViewBody.total.centAmount}/>}
                </div>
                <Link href="/cart" title="">
                    <PrimaryButton className="w-full" margin="20px 0 0 0" inCartFooter={true}>
                        {$t("To Payment")}
                    </PrimaryButton>
                </Link>
            </div>
        </div>);
};
export const HeaderBasket = observer(() => {
    var _a, _b;
    const { cartStore } = useStores();
    const { $t } = useI18n();
    const { value: newCartPopup } = useGate("cart");
    if (newCartPopup)
        return;
    if (!(cartStore.isCartViewLoaded && cartStore.cartTotalItems > 0)) {
        return (<div className="w-[480px] bg-white p-5 lg:py-7.5 lg:px-11.5">
                <h2 className="text-xl capitalize text-black">{$t("YOUR SHOPPING BASKET IS STILL EMPTY")}</h2>
                <p className="text-sm">{$t("Go shopping! :-)")}</p>
            </div>);
    }
    const displayLineItems = ((_b = (_a = cartStore.cartView.body) === null || _a === void 0 ? void 0 : _a.lineItems) === null || _b === void 0 ? void 0 : _b.slice(0, 2)) || [];
    return (<div className="mb-0 min-w-[480px]">
            <div className="bg-white py-0 px-5">
                <div className="mt-6 px-4">
                    <div className="text-base text-black">
                        {displayLineItems.length} {$t("OF")} {cartStore.cartLineItemsCount} {$t("ITEMS")}
                    </div>
                    <Link className="text-black no-underline hover:text-black" href="/cart" title="">
                        <ArrowText text={$t("View all")} marginBot="20px"/>
                    </Link>
                </div>
                <div className="overflow-x-hidden pr-4">
                    {displayLineItems.map((item) => (<BasketCartItem data={item} key={item.id}/>))}
                </div>
                <HeaderBasketCartFooter cartViewBody={cartStore.cartView.body}/>
            </div>
        </div>);
});
