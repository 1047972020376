import { RadioTabBase, RadioTabWrapperBase } from "@jog/react-components";
import { styled } from "@jog/theming";
import { theme } from "layout/theme";
import React from "react";
const RadioTabWrapper = styled(RadioTabWrapperBase) `
    margin-bottom: 30px;
    padding-bottom: 30px;
    color: ${theme.color.black};
    font-size: 14px;
    border-bottom: 1px solid ${theme.color.mediumGray};
`;
export const RadioTab = React.memo(RadioTabBase({
    RadioTabWrapper,
    tabHeaderWrapperCss: {
        ".tab-head__right-wrapper": {
            color: theme.color.black,
            ".location-icon::before": {
                fontSize: "14px",
            },
        },
        ".jc-green": {
            color: theme.color.black,
        },
    },
    distanceLabelColor: theme.color.black,
}));
