import { Price } from "@/components";
import { Link, NextImage, useI18n, useLineItemLocalized, useLineItemPrice } from "@jog/react-components";
import { useTheme } from "@jog/theming";
const Property = ({ label, children }) => {
    return (<div className="text-center font-primary text-15 font-medium">
            <p className="mb-2.5 font-primary font-normal capitalize text-black">{label}</p>
            {children}
        </div>);
};
const PriceContainer = ({ data }) => {
    const theme = useTheme();
    const { finalPrice, firstPrice } = useLineItemPrice(data);
    if (finalPrice) {
        return (<div className="flex">
                <div className="text-left lg:text-right">
                    <Price color={theme.color.gray} fontSize="12px" isLabelShow={false} isOldPrice price={firstPrice}/>
                </div>
                <div className="text-left font-primary font-normal lg:text-right">
                    <Price color={theme.color.mainRed} fontSize="16px" marginLeft="10px" price={finalPrice}/>
                </div>
            </div>);
    }
    return <Price fontSize="16px" price={firstPrice}/>;
};
export const BasketCartItem = ({ data }) => {
    const { link, name, promotion } = useLineItemLocalized(data);
    const { $t } = useI18n();
    return (<div className="w-full border-t border-t-gray-lightSand py-5 px-0">
            <div className="flex ">
                <div className="shink-0 self-center">
                    <Link href={link} title="" className="block w-full">
                        <NextImage blur={false} height={105} width={70} src={data.image} alt="Cart item image"/>
                    </Link>
                </div>
                <div className="grid flex-1 grid-cols-12 pl-4">
                    <div className="col-span-8">
                        <Link href={link} title="">
                            <h6 className="mb-2.5 w-full text-13 font-medium uppercase">{name}</h6>
                        </Link>
                        <div className="grid grid-cols-3">
                            <Property label={$t("Size")}>{data.size}</Property>
                            {data.length && <Property label={$t("Length")}>{data.length}</Property>}
                            <Property label={$t("Quantity")}>{data.quantity}</Property>
                        </div>
                        {promotion && <div className="font-primary text-13 font-medium text-red-main">{promotion}</div>}
                    </div>
                    <div className="col-span-12 flex items-center justify-end lg:col-span-4">
                        <PriceContainer data={data}/>
                    </div>
                </div>
            </div>
        </div>);
};
